import {AdditionalChartInfo, ChartDataModel} from "./chart-data-model";
import {
  ConsumptionDataCompanyPartner,
  ConsumptionDataForYear
} from "./consumption-data-model";
import {TreeNode} from "../material-components/filter-tree/filter-tree.component";
import {TranslateService} from "@ngx-translate/core";
export class ChartDataMapper {
  public static toChartDatasetData(translate: TranslateService,
                                   data: ConsumptionDataCompanyPartner | undefined,
                                   yearToDisplay: number,
                                   monthToDisplay: number | undefined = undefined,
                                   meteringPointFilter: {mpid: string, vnb: string, bp: string}[] | undefined = undefined,
                                   getValuesForMonth: boolean = false) {

    let chartData: ChartDataModel = new ChartDataModel();
    if(data == undefined || monthToDisplay == undefined && getValuesForMonth) {
      chartData.contingentData = [];
      chartData.currentData = [];
      chartData.previousData = [];
      chartData.additionalInfoCurrent = [];
      chartData.additionalInfoPrevious = [];
      return chartData;
    }

    let pointDataFiltered = (data.businessPartners || [])
      .filter(bp => meteringPointFilter == undefined || meteringPointFilter.filter(f => f.bp === bp.name).length > 0) // only selected businesspartners
      .flatMap(bp => bp.meteringPoints)  // array of all meteringPoints for all relevant businessPartners
      .filter(mp => meteringPointFilter == undefined || meteringPointFilter.map(f => f.mpid+f.vnb).includes(mp.id+mp.mpa.id)) // only selected MPs
      .flatMap(mp => mp.dataForYear); // strip MPID -> array of consumptionDataForYear with possibly multiple entries per year

    //console.log("_________________ start mapping [current]");
    chartData.currentData = this.sumMpDataForPeriod(pointDataFiltered, yearToDisplay, false,getValuesForMonth ? monthToDisplay : undefined);
    //console.log("_________________ start mapping [previous]");
    chartData.previousData = this.sumMpDataForPeriod(pointDataFiltered, yearToDisplay - 1, false,getValuesForMonth ? monthToDisplay : undefined);
    //console.log("_________________ start mapping [contingent]");
    chartData.contingentData = this.sumMpDataForPeriod(pointDataFiltered, yearToDisplay, true, getValuesForMonth ? monthToDisplay : undefined);
    //console.log("_________________ start mapping [additional current]");
    chartData.additionalInfoCurrent = this.generateCompleteness(translate, pointDataFiltered, yearToDisplay, getValuesForMonth ? monthToDisplay : undefined);
    //console.log("_________________ start mapping [additional previous]");
    chartData.additionalInfoPrevious = this.generateCompleteness(translate, pointDataFiltered, yearToDisplay - 1, getValuesForMonth ? monthToDisplay : undefined);

    return chartData;
  }

  private static getNumOfValues(year: number, month: number | undefined) {
    return month === undefined ? 12 :
      new Date(year, month + 1, 0).getDate(); // day 0: last day of previous month. month + 1 to get last day of displayed month
  }

  private static prepareValues(data: ConsumptionDataForYear[], year: number, contingent: boolean, month: number | undefined, dataSource: boolean = false) {
    //console.log("preparing values ", year, month, dataSource, data);
    let dataForRelevantYear = data
      .filter(dfy => dfy.year == year);
    if(dataForRelevantYear.length == 0) {
      return [];
    }

    // all data for the same year anyway, only interested in values (per meteringPoint) now.

    if(contingent) {
      //console.log("CONTINGENT", dataForRelevantYear.map(dfcy => dfcy.contingents));
      return dataForRelevantYear.map(dfcy => (dfcy.contingents || [])
        .map((v: number) => v < 0 ? null : v)); // Backend returns -1 for missing values. Map to null to avoid wrong sums
    }

    const relevantValues = month !== undefined ?
      // line chart
      dataForRelevantYear.flatMap(dfcy =>
        dfcy.dailyValues
        .filter(dv => dv.month == month + 1)) : // month from backend is 1-based
      // bar chart
      dataForRelevantYear;
    if(dataSource) {
      return relevantValues.map(dv => dv.dataSource) || [[]];
    }
    // Backend returns -1 for missing values. Map to null to avoid wrong sums
    return relevantValues.map(dv => dv.values.map(v => v < 0 ? null : v)) || [[]];
  }

  private static sumMpDataForPeriod(data: ConsumptionDataForYear[], year: number, contingent: boolean, month: number | undefined): (null | number)[] {
    //console.log("sum data ", year, month, data);
    let values = this.prepareValues(data, year, contingent, month);
    if(values.length == 0) {
      return [];
    }
    //console.log("values after prepare ", year, month, values);

    let numOfValues = this.getNumOfValues(year, month);

    let distributed = (values as unknown as (number | null)[][])
      .reduce((sum: (null | number)[], nextArrayForYear: (null | number)[]) => // reduce an array of DataForYear-values to one DataForYear-Array. sum up MPs
          sum.map((s: null | number, i: number) => // add next[0] to sum[0] etc. via map
              nextArrayForYear[i] == null ? s : // null values don't contribute to sum
              s == null ? nextArrayForYear[i] :
              s + nextArrayForYear[i]!),
        new Array(numOfValues).fill(null));

    // bar chart - return distributed data
    if(month == undefined) {
      const ret = distributed.map((dfy: number | null) => dfy == null ? null : Math.floor(dfy)); // no decimal point desired (ELDEXDHUB-3114);
      return ret.filter(e => e != null).length == 0 ? [] : ret;
    }

    // data for linechart needs to be cumulated
    if(contingent) {
      const contingent = distributed[month] != null ? distributed[month]!/distributed.length : null;
      distributed = Array(distributed.length).fill(contingent);
    }

    let lastCumulationValue: number | null = null;
    let cumulated: (number | null)[] = [distributed[0]];
    for(let i = 1; i < distributed.length; i++) {
      const nextValue: number | null =
        distributed[i] == null ? null :
          cumulated[i-1] == null ?
            lastCumulationValue === null ? distributed[i] :
              lastCumulationValue + distributed[i]! :
            cumulated[i-1]! + distributed[i]!;
      cumulated.push(nextValue);
      lastCumulationValue = nextValue === null ? lastCumulationValue : nextValue;
    }
    // for optical debugging
    /*if(contingent) {
      cumulated = cumulated.map(c => c != null ? c+100000 : c);
    }*/
    const ret = cumulated.map((dfy: number | null) => dfy == null ? null : Math.floor(dfy)); // no decimal point desired (ELDEXDHUB-3114);
    return ret.filter(e => e != null).length == 0 ? [] : ret;
  }

  private static generateCompleteness(translate: TranslateService, data: ConsumptionDataForYear[], year: number, month: number | undefined): AdditionalChartInfo[] {
    //console.log("generate completeness for ",year, month, data);
    let values = this.prepareValues(data, year, false, month, true);
    if(values.length == 0) {
      return [];
    }

    let numOfValues = this.getNumOfValues(year, month);
    const iterator = new Array(numOfValues).fill(0);
    const defaultKey = 'DATA_SOURCE_UNSPECIFIED';

    //console.log("values after prepare: ",values);
    return (values as string[][]).reduce((prev: AdditionalChartInfo[], next: string[]) =>
      iterator.map((_, i) => {
        //console.log(i," prev: ",prev[i]?.key, "next: ",next[i]);
        if(next[i] === undefined) next[i] = defaultKey;
        const key = (prev[i].key === defaultKey || prev[i].key === next[i] ?
          next[i] :
          next[i] === defaultKey ? prev[i].key :
            prev[i].key.startsWith('SDAT') && next[i].startsWith('SDAT') ?
              'SDAT_INCOMPLETE' :
              'MIXED');
        return {
          isComplete: (prev[i] === undefined || prev[i].isComplete) && (next[i] === undefined || next[i] !== 'SDAT_INCOMPLETE'),
          key: key,
          dataSource: translate.instant('LabelMeteringDataSource.' + key)
        }
      }), new Array(numOfValues).fill({isComplete: true, dataSource: '', key: defaultKey}));

  }

  public static getMinYear(data: ConsumptionDataCompanyPartner) {
    return Math.min(
      ...data.businessPartners?.flatMap(bp =>
        bp.meteringPoints?.flatMap(m =>
          m.dataForYear?.map(dfy => dfy.year))));
  }

  public static toFilterTree(data: ConsumptionDataCompanyPartner | undefined): TreeNode[] {
    let ret: TreeNode[] = [];
    if(data == undefined) {
      return ret;
    }

    data.businessPartners?.forEach(bp => {
      const children = bp.meteringPoints
        .map(mp => {
          return {
            label: mp.id,
            selected: !mp.excluded,
            data: {
              mpid: mp.id,
              vnb: mp.mpa.id,
              bp: bp.name,
              flagged: mp.excluded
            },
          }});
      const parentSelected = children.reduce((p: boolean | null | undefined, c) => p === undefined || p === c.selected? c.selected : null, undefined);
      ret.push({
        label: bp.name,
        data: bp.name,
        selected: parentSelected === undefined ? true : parentSelected,
        children: children
      });
    })

    return ret;
  }
}
