import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {SubmitButtonComponent} from "./submit-button/submit-button.component";
import {PageLoadingSpinnerComponent} from "./page-loading-spinner/page-loading-spinner.component";
import {MatIconComponent} from "./mat-icon-component/mat-icon.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonModule} from "@angular/material/button";
import {SharedModule} from "../modules/shared.module";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatTabsModule} from "@angular/material/tabs";
import {MatTableModule} from "@angular/material/table";
import {MatNativeDateModule, MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatInputModule} from "@angular/material/input";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatDialogModule} from "@angular/material/dialog";
import {MatRadioModule} from "@angular/material/radio";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatCardModule} from "@angular/material/card";
import {PasswordFieldComponent} from "./password-field/password-field.component";
import {FormControlPipe} from "../services/form-control.pipe";
import {FilterTreeComponent} from "./filter-tree/filter-tree.component";
import {MatTreeModule} from "@angular/material/tree";
import {BarChartComponent} from "../chart/bar-chart/bar-chart.component";
import {LineChartComponent} from "../chart/line-chart/line-chart.component";
import {NgChartsModule} from "ng2-charts";
import {
  MeteringPointsTableComponent
} from "./metering-points-table/metering-points-table.component";
import {RouterLink} from "@angular/router";
import { MeteringPointsConsumptionTableComponent } from './metering-points-consumption-table/metering-points-consumption-table.component';
import {ChartsWrapperComponent} from "../chart/charts-wrapper/charts-wrapper.component";
import { MatTooltipModule} from "@angular/material/tooltip";
import {MatDatepickerModule} from "@angular/material/datepicker";
import { ContingentTradesTableComponent } from './contingent-trades-table/contingent-trades-table.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgChartsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatTabsModule,
    NgOptimizedImage,
    MatTableModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatInputModule,
    MatSnackBarModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatTreeModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterLink
  ],
  declarations: [
    SubmitButtonComponent,
    PageLoadingSpinnerComponent,
    MatIconComponent,
    PasswordFieldComponent,
    FilterTreeComponent,
    BarChartComponent,
    LineChartComponent,
    ChartsWrapperComponent,
    MeteringPointsTableComponent,
    FormControlPipe,
    MeteringPointsConsumptionTableComponent,
    ContingentTradesTableComponent
  ],
  exports: [
    SubmitButtonComponent,
    PageLoadingSpinnerComponent,
    MatIconComponent,
    PasswordFieldComponent,

    MatProgressSpinnerModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatTabsModule,
    NgOptimizedImage,
    MatTableModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatInputModule,
    MatSnackBarModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FilterTreeComponent,
    BarChartComponent,
    LineChartComponent,
    ChartsWrapperComponent,
    MeteringPointsTableComponent,
    MeteringPointsConsumptionTableComponent,
    ContingentTradesTableComponent
  ]
})
export class MaterialModule { }
