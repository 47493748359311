<p>
  <mat-icon svgIcon="info-circle" class="text-info" style="vertical-align: middle"/>
  <span style="vertical-align: middle"> {{prefix+'disclaimer' | translate}}</span>
</p>
<table mat-table [dataSource]="tradedContingents" class="table-striped">
  <ng-container matColumnDef="tradeAction">
    <th mat-header-cell *matHeaderCellDef>{{tcPrefix+'action' | translate}}</th>
    <td mat-cell *matCellDef="let trade"> {{trade.tradingAction}} </td>
  </ng-container>

  <ng-container matColumnDef="period">
    <th mat-header-cell *matHeaderCellDef>{{tcPrefix+'period' | translate}}</th>
    <td mat-cell *matCellDef="let trade"> {{trade.month}}.{{trade.year}} </td>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef>{{tcPrefix+'quantity' | translate}}</th>
    <td mat-cell *matCellDef="let trade"> {{trade.tradingQuantity}} </td>
  </ng-container>

  <ng-container matColumnDef="datetime">
    <th mat-header-cell *matHeaderCellDef>{{tcPrefix+'datetime' | translate}}</th>
    <td mat-cell *matCellDef="let trade"> {{trade.tradingTimestamp | dateTime}} </td>
  </ng-container>

  <ng-container matColumnDef="partner">
    <th mat-header-cell *matHeaderCellDef>{{tcPrefix+'partner' | translate}}</th>
    <td mat-cell *matCellDef="let trade"> {{trade.tpCompanyName}} </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let trade">
      <a *ngIf="!useActionEmitter" mat-icon-button [routerLink]="'/company/contingents/trade/'+trade.tradeId" [id]="'trade_info_'+trade.tradeId">
        <mat-icon svgIcon="chevron-right"></mat-icon>
      </a>
      <button *ngIf="useActionEmitter" mat-icon-button (click)="goToDetails.emit(trade.tradeId)">
        <mat-icon svgIcon="chevron-right"></mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
