import { Component } from '@angular/core';
import {StandardViewComponent} from "../../shared/marketpartner-components/standard-view/standard-view.component";
import {companyservice} from "../../shared/messaging-grpc/messaging-grpc";
import ContingentRate = companyservice.ContingentRate;
import {USERS_API_ENDPOINTS_LIST} from "../requests/api-endpoints-list";
import {ApiRequestService} from "../../shared/api-request/service/api-request.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AdminOstralLettersDialogComponent} from "../admin-ostral-letters-dialog/admin-ostral-letters-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'admin-ostral',
  templateUrl: './admin-ostral.component.html',
  styleUrls: ['./admin-ostral.component.scss']
})
export class AdminOstralComponent extends StandardViewComponent {
  initialized = false;
  contingentData: ContingentRate[] = [];
  displayedColumns = ["year", "month", "rate", "consumptionGrowthThreshold", "action"];
  prefix = 'Contingents.';
  rateToAdd = {year: undefined, month: undefined, rate: undefined, consumptionGrowthThreshold: 0.2};

  constructor(public activatedRoute: ActivatedRoute,
              public router: Router,
              private requestService: ApiRequestService,
              public dialog: MatDialog) {
    super(activatedRoute, router);
  }
  async onParamsUrlInitialized(params: any) {
    super.onParamsUrlInitialized(params);
    this.loadContingents();
  }

  private loadContingents() {
    this.requestService.doApiRequest(USERS_API_ENDPOINTS_LIST.getContingentRates, undefined,false, true)
      .then(result => {
        this.contingentData = [];
        if(result.status == 'success') {
          this.contingentData = result.data.contingentRates;
        }
        this.contingentData.push({year: undefined, month: undefined, rate: undefined, consumptionGrowthThreshold: undefined});
        this.setInitialized(true);
      });
  }

  deleteRate(rate: ContingentRate) {
    this.requestService.doApiRequest(USERS_API_ENDPOINTS_LIST.deleteContingentRate, rate, false, true)
      .then(result => {
        if(result.status == 'success') {
          this.loadContingents();
        }
      });
  }

  addRate(rate: any) {
    this.requestService.doApiRequest(USERS_API_ENDPOINTS_LIST.setContingentRate, rate, false, true)
      .then(result => {
        if(result.status == 'success') {
          this.rateToAdd = {year: undefined, month: undefined, rate: undefined, consumptionGrowthThreshold: 0.2};
          this.loadContingents();
        }
      });
  }

  openLetterDialog() {
    this.dialog.open(AdminOstralLettersDialogComponent, {
      data: {},
    });
  }

  validRate(): boolean {
    return this.rateToAdd.year != undefined && this.rateToAdd.month != undefined && this.rateToAdd.rate != undefined &&
      this.rateToAdd.year > 999 && this.rateToAdd.year < 10000 &&
      this.rateToAdd.month > 0 && this.rateToAdd.month <= 12 &&
      this.rateToAdd.rate > 0 && this.rateToAdd.rate < 1;
  }
}
