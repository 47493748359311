import {Component} from '@angular/core';
import {MarketpartnerViewComponent} from "../shared/marketpartner-view.component";
import {SessionService} from "../../shared/session/session.service";
import {MarketPartnerService} from "../../shared/services/marketpartner.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiRequestService} from "../../shared/api-request/service/api-request.service";
import {TranslateService} from "@ngx-translate/core";
import {USERS_API_ENDPOINTS_LIST} from "../requests/api-endpoints-list";
import {ConsumptionDataCompanyPartner} from "../../shared/chart/consumption-data-model";
import {MatDialog} from "@angular/material/dialog";
import {
  UsersOstralMeteringpointDialogComponent
} from "../users-ostral-meteringpoint-dialog/users-ostral-meteringpoint-dialog.component";
import {MeteringPoint} from "../../companypartners/metering-points/metering-point";
import {COMPANYPARTNERS_API_ENDPOINTS_LIST} from "../../companypartners/api-endpoints-list";
import {companyservice} from "../../shared/messaging-grpc/messaging-grpc";
import ContingentTrade = companyservice.ContingentTrade;
import {
  UsersOstralContingentTradeDialogComponent
} from "../users-ostral-contingent-trade-dialog/users-ostral-contingent-trade-dialog.component";

@Component({
  selector: 'users-ostral-info',
  templateUrl: './users-ostral-info.component.html',
  styleUrls: ['../users-common.scss', './users-ostral-info.component.scss']
})
export class OstralInfoComponent extends MarketpartnerViewComponent {

  businessPartnerId!: string;
  consumptionData: ConsumptionDataCompanyPartner = {businessPartners: []};
  gpInfo: {name: string, address: string, status: string, contact: string} | undefined;
  upInfo: {name: string, address: string, contact: string, email: string, phone: string} | undefined;
  meteringPoints: MeteringPoint[] = [];
  tradedContingents: ContingentTrade[] = [];
  showInfoMultipleVnb: boolean = false;
  isExclusionEditable = false;

  constructor(public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              public apiRequestService: ApiRequestService,
              public router: Router,
              public translate: TranslateService,
              public dialog: MatDialog
  ) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }
  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    if (!this.partnerId || !params.mpId || !params.gpId) return;

    this.businessPartnerId = params.gpId;

    this.isExclusionEditable = this.marketPartnerRole === 'VNB';

    const promises = [];

    const getBusinessPartnerPromise = this.apiRequestService.doApiRequest(
      USERS_API_ENDPOINTS_LIST.getBusinessPartner,
      {partnerId: this.partnerId, businessPartnerId: this.businessPartnerId, returnLinkedCompanyPartner: true});
    promises.push(getBusinessPartnerPromise);

    if(this.isOperatorMode) {
      const getMeteringPointsPromise = this.apiRequestService.doApiRequest(
        USERS_API_ENDPOINTS_LIST.listMeteringPoints,
        {partnerId: this.partnerId, businessPartnerId: this.businessPartnerId}
      );
      promises.push(getMeteringPointsPromise);
    } else {
      const getConsumptionDataPromise = this.apiRequestService.doApiRequest(
        USERS_API_ENDPOINTS_LIST.getConsumptionData,
        {partnerId: this.partnerId, businessPartnerId: this.businessPartnerId, yearOfDailyValues: 0});
      promises.push(getConsumptionDataPromise);

      const getContingentTradesPromise = this.apiRequestService.doApiRequest(
        COMPANYPARTNERS_API_ENDPOINTS_LIST.listContingentTrades,
        {tradeId: 0, cpPartnerId: 0, vnbPartnerId: this.partnerId, businessPartnerId: this.businessPartnerId});

      promises.push(getContingentTradesPromise);
    }

    Promise.all(promises).then(result => {
      const getBusinessPartnerResult = result[0];
      const getMeteringPointsResult = result[1];
      const contingentTradesResult = result[2];

      //getBusinessPartner
      if(getBusinessPartnerResult.status == "success" && getBusinessPartnerResult.data.result.status.toLowerCase() == "success") {

        // Info from upload (businessPartner)
        let gpInfoUnmapped = getBusinessPartnerResult.data.businessPartner;
        this.gpInfo = {
          name: gpInfoUnmapped.name,
          address: gpInfoUnmapped.addressStreet + " " + gpInfoUnmapped.addressHouseNumber + ", " + gpInfoUnmapped.addressZipCode + " " + gpInfoUnmapped.addressTown,
          status: gpInfoUnmapped.registrationStatus,
          contact: gpInfoUnmapped.contactFirstName + " " + gpInfoUnmapped.contactName
        }

        // Info from company (companyPartner)
        let companyInfoUnmapped = getBusinessPartnerResult.data.companyPartner;
        if (companyInfoUnmapped != undefined) {
          this.upInfo = {
            name: companyInfoUnmapped.name,
            address: companyInfoUnmapped.addressStreet + " " + companyInfoUnmapped.addressHouseNumber + ", " + companyInfoUnmapped.addressZipCode + " " + companyInfoUnmapped.addressTown,
            contact: companyInfoUnmapped.contactFirstName + " " + companyInfoUnmapped.contactName,
            email: companyInfoUnmapped.contactEmail,
            phone: companyInfoUnmapped.contactPhone
          }
        }

        this.showInfoMultipleVnb = getBusinessPartnerResult.data.hasMultipleVnb;
      } else {
        this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
      }

      //getMeteringPoints
      if(getMeteringPointsResult.status == "success") {
        if(this.isOperatorMode) {
          this.meteringPoints = getMeteringPointsResult.data.meteringPoints;
          // Don't show valid for operators. The backend doesn't return that.
          this.meteringPoints.forEach((mp: any) => { mp.valid = {}; mp.valid.from = ''; mp.valid.to = ''});
        } else {
          this.consumptionData = getMeteringPointsResult.data;
          this.meteringPoints = getMeteringPointsResult.data.businessPartners.flatMap((bp: any) => bp.meteringPoints);
        }
      } else {
        this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
      }

      //getContingentTrades
      this.tradedContingents = contingentTradesResult.data.contingentTrade;

      // End
      this.setInitialized(true);
    });
  }

  goBack() {
    this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
  }

  showMeteringPointDetails(id: string) {
    this.apiRequestService.doApiRequest(USERS_API_ENDPOINTS_LIST.getMeteringPointMonthlyDetails,
      {
        selector: {id: id, administratorId: this.partnerId},
        businessPartner: {partnerId: this.partnerId, businessPartnerId: this.businessPartnerId}
      })
      .then(result => {
        if(result.status == 'success') {
          this.dialog.open(UsersOstralMeteringpointDialogComponent, {
            data: {
              id: id,
              consumptionData: result.data.monthlyConsumption
            }
          });
        }
      });
  }

  onExclusionUpdated(meteringPoint: MeteringPoint) {
    this.apiRequestService.doApiRequest(USERS_API_ENDPOINTS_LIST.setContingencyState, {
      partnerId: meteringPoint.mpa.id,
      businessPartnerId: meteringPoint.businessPartnerId,
      meteringPointId: meteringPoint.id,
      excluded: meteringPoint.excluded
    }).then((result) => {
      if(result && result.status == "success") {
        this.consumptionData = {businessPartners: this.consumptionData.businessPartners};
      }
    });
  }

  showContingentTradeDetails(id: string) {
    this.dialog.open(UsersOstralContingentTradeDialogComponent, {
      data: {
        trade: this.tradedContingents.find(trade => trade.tradeId === id)
      }
    });
  }
}
