<h1 mat-dialog-title>{{'companyClient.navigation.trade' | translate}}</h1>

<div mat-dialog-content>
  <div class="multi-col-form">
    <div class="col-50">
      <div class="rowspan">
        <span>{{ctPrefix+'action' | translate}}</span>
        <span>{{ 'TradingAction.'+data.trade.tradingAction | translate }}</span>
      </div>
      <span class="half">
        <span>{{ctPrefix+'periodMonth' | translate}}</span>
        <span>{{ 'months.'+data.trade.month | translate }}</span>
      </span>
      <span class="half">
        <span>{{ctPrefix+'periodYear' | translate}}</span>
        <span>{{data.trade.year}}</span>
      </span>
      <span class="rowspan">
        <span>{{ctPrefix+'tradedQuantity' | translate}}</span>
        <input matInput type="number" name="tradingQuantity" id="tradingQuantity">
      </span>
    </div>

    <div class="col-50">
      <span class="half">
        <span>{{ctPrefix+'tradeDate' | translate}}</span>
        <input matInput name="tradingTimestampDate" id="tradingTimestampDate">
      </span>
      <span class="half">
        <span>{{ctPrefix+'tradeTime' | translate}}</span>
        <input matInput type="text"  name="tradingTimestampTime" id="tradingTimestampTime">
      </span>
      <span class="rowspan">
        <span>{{ctPrefix+'platform' | translate}}</span>
        <input matInput type="text" name="tradingPlatformOrIntermediary" id="tradingPlatformOrIntermediary">
      </span>
      <span class="rowspan">
        <span>{{ctPrefix+'contingentId' | translate}}</span>
        <input matInput type="text" name="tradingContingentId" id="tradingContingentId">
      </span>
    </div>
  </div>

  <div class="multi-col-form">
    <div class="col-50">
      <h2>{{prefix+'partnerInfo' | translate}}</h2>
      <span class="rowspan">
        <span>{{ctPrefix+'company.name' | translate}}</span>
        <input matInput type="text" name="tpCompanyName" id="tpCompanyName">
      </span>
      <span class="rowspan">
        <span>{{ctPrefix+'company.uid' | translate}}</span>
        <input matInput type="text" name="tpUidNumber" id="tpUidNumber">
      </span>
      <span class="rowspan">
        <span>{{ctPrefix+'contingentId' | translate}}</span>
        <input matInput type="text" name="tpContingentId" id="tpContingentId">
      </span>
      <span class="rowspan">
        <span>{{ctPrefix+'company.responsible' | translate}}</span>
        <input matInput type="text" name="tpResponsiblePerson" id="tpResponsiblePerson">
      </span>
      <span class="half">
        <span>{{ctPrefix+'company.contactFirst' | translate}}</span>
        <input matInput type="text" name="tpContactFirstName" id="tpContactFirstName">
      </span>
      <span class="half">
        <span>{{ctPrefix+'company.contactLast' | translate}}</span>
        <input matInput type="text" name="tpContactName" id="tpContactName">
      </span>

      <span class="rowspan-with-very-small">
        <span>{{'FormStreet' | translate}}</span>
        <input matInput type="text" name="tpAddressStreet" id="tpAddressStreet">
      </span>
      <span class="very-small-input">
        <span>{{'FormNr' | translate}}</span>
        <input matInput type="text" name="tpAddressHouseNumber" id="tpAddressHouseNumber">
      </span>
      <span class="rowspan">
        <span>{{ctPrefix+'company.adressSuffix' | translate}}</span>
        <input matInput type="text" name="tpAddressSuffix" id="tpAddressSuffix">
      </span>
      <span class="small-input">
        <span>{{'FormZip' | translate}}</span>
        <input matInput type="text" name="tpAddressZipCode" id="tpAddressZipCode">
      </span>
      <span class="rowspan-with-small">
        <span>{{'FormTown' | translate}}</span>
        <input matInput type="text" name="tpAddressTown" id="tpAddressTown">
      </span>

      <span class="rowspan">
        <span>{{ctPrefix+'company.email' | translate}}</span>
        <input matInput type="text" name="tpEmail" id="tpEmail">
      </span>
    </div>

    <div class="col-50">
      <h2>{{prefix+'ownInfo' | translate}}</h2>
      <span class="rowspan">
        <span>{{ctPrefix+'company.name' | translate}}</span>
        {{data.trade.cpCompanyName}}
      </span>
      <span class="rowspan">
        <span>{{ctPrefix+'company.uid' | translate}}</span>
        <input matInput type="text" name="cpUidNumber" id="cpUidNumber">
      </span>
      <span class="rowspan">
        <span>{{ctPrefix+'contingentId' | translate}}</span>
        <input matInput type="text" name="cpContingentId" id="cpContingentId">
      </span>
      <span class="rowspan">
        <span>{{ctPrefix+'company.responsible' | translate}}</span>
        <input matInput type="text" name="cpResponsiblePerson" id="cpResponsiblePerson">
      </span>
      <span class="half">
        <span>{{ctPrefix+'company.contactFirst' | translate}}</span>
        <input matInput type="text" name="cpContactFirstName" id="cpContactFirstName">
      </span>
      <span class="half">
        <span>{{ctPrefix+'company.contactLast' | translate}}</span>
        <input matInput type="text" name="cpContactName" id="cpContactName">
      </span>

      <span class="rowspan-with-very-small">
        <span>{{'FormStreet' | translate}}</span>
        <input matInput type="text" name="cpAddressStreet" id="cpAddressStreet">
      </span>
      <span class="very-small-input">
        <span>{{'FormNr' | translate}}</span>
        <input matInput type="text" name="cpAddressHouseNumber" id="cpAddressHouseNumber">
      </span>
      <span class="rowspan">
        <span>{{ctPrefix+'company.adressSuffix' | translate}}</span>
        <input matInput type="text" name="cpAddressSuffix" id="cpAddressSuffix">
      </span>
      <span class="small-input">
        <span>{{'FormZip' | translate}}</span>
        <input matInput type="text" name="cpAddressZipCode" id="cpAddressZipCode">
      </span>
      <span class="rowspan-with-small">
        <span>{{'FormTown' | translate}}</span>
        <input matInput type="text" name="cpAddressTown" id="cpAddressTown">
      </span>

      <span class="rowspan">
        <span>{{ctPrefix+'company.email' | translate}}</span>
        <input matInput type="text" name="cpEmail" id="cpEmail">
      </span>
    </div>
  </div>
</div>

<div mat-dialog-actions [align]="'end'" class="padded-actions">
  <button mat-flat-button (click)="onNoClick()" id="modalCancel">{{'ModalCloseButton' | translate}}</button>
</div>
